<template>
  <span class="float-md-right d-none d-md-block">
    COPYRIGHT  © {{ new Date().getFullYear() }}
    <b-link
      class="ml-25"
      href="https://www.devnx.com.br"
      target="_blank"
    >DevN-X</b-link>
    <span class="d-none d-sm-inline-block">, Todos os direitos reservados</span>
  </span>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
