export default [
  {
    header: 'Bar Doce Lar',
  },
  {
    icon: 'UserIcon',
    route: 'app-comandas',
    title: 'Comandas',
    action: 'admin',
  },
  {
    icon: 'UserIcon',
    route: 'app-portaria',
    title: 'Portaria',
    action: 'admin',
  },
  {
    icon: 'UserIcon',
    route: 'app-marketing',
    title: 'Marketing',
    action: 'admin',
  },
  {
    icon: 'HomeIcon',
    title: 'Notas',

    action: 'admin',
    children: [
      {
        title: 'NF Simples',
        route: 'app-nfsimples',
      },
      {
        title: 'NF Detalhado',
        route: 'app-nfdetalhado',
      },
    ],
  },
  {
    icon: 'UserIcon',
    route: 'app-eventos',
    title: 'Eventos',
    action: 'admin',
  },
]
